body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.map-container {
  height: 400px;
  width: 100%;
}

.map-wrap {
  position: relative;
  width: 100%;
  height: 50vh; /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 99%;
  height: 100%;
}

.hideOnMobile {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }
}

.hideOnDesktop {
  display: block;
}
@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

@media print {
  @page {
    size: landscape;
    margin: 3mm;
  }
}